import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './abtt.css'


const Abtt = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className='  abtt-container' >
        <div className="container abtt-inner">
            <div className="right-text" data-aos="fade-up"data-aos-duration="3000">
           

            <h2>THE PLIGHT OF <span>HOMELESS</span> DOGS        </h2>
            </div>
            <div className="left-text" data-aos="fade-up"
     data-aos-easing="linear"
     data-aos-duration="1500">
              <h2 className='right-text__heading'>The Problem</h2>
              <p className="">Shelters are overcrowded, and many dogs are living on the streets without proper care, food, and medical attention. Dog rescue organisations have limited resources and rely on donations to carry out their work. With SHIBADOPT, we aim to increase financial support to these organisations, providing more dogs with a chance to find a loving forever home.</p>
              <div className="divider"></div>
              <h2 className="right-text__heading">The Solution</h2>
              <p className="">By providing a decentralized platform for dog lovers to support the cause, the SHIBADOPT  team is creating a sustainable solution for the long-term welfare of these animals</p>
            </div>
        </div>
       
       
    </div>
  )
}

export default Abtt
