

import dog1 from '../.././/asset/bulldog_8061012.png'
import dog2 from '../.././/asset/dog_1351711.png'
import dog3 from '../.././/asset/dog_3464341.png'



const data=[
    {id:1, icon: dog1, className: "icon1",title: "Impactfull", desc: "SHIBADOPT provides an opportunity for dog lovers globally to make a meaningful impact in the lives of homeless dogs. Every investment will go a long way in providing more dogs with a second chance to find a loving home."},
    {id:2, icon: dog2,className: "icon2", title: "Inovative", desc: "SHIBADOPT is a unique project that combines the power of blockchain technology and community-driven initiatives to create a sustainable solution for dog rescue organisations. It provides a transparent and secure platform for people to contribute to the cause without intermediaries."},
    {id:3, icon: dog3, className: "icon3",title: "Empowering", desc: " SHIBADOPT empowers dog rescue organisations and enthusiasts to come together and make a difference. It provides a platform for people to give back and support these furry friends, and it reminds us that every dog deserves a chance to find a loving home. "}
    
]
export default data