import Navbar from './component/Navbar/Navbar/Navbar';
import './index.css'


import './App.css';
import Hero from './component/Hero1/Hero1'
import About from './component/About/About';
import Roadmap from './component/Roadmap/Roadmap';
import Tokenomics from './component/Tokenomics/Tokenomics';
import Footer from './component/Footer/Footer';
import Abtt from './component/Abtt/Abtt';
import Feature from './component/Feature';


function App() {
  return (
    <div>
      <Navbar/>
      <Hero/>

     <Abtt/>
      
     <About/>
    
      {/* <Tokenomics/>  */}
     <Footer/>
    </div>
  );
}

export default App;
