
import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './hero1.css';
import lig from '../../asset/bg1.png';
import twitter from '../../asset/twitter.png';
import etherscan from '../../asset/etherscan.png';
import telegram from '../../asset/telgram.png';
import uniswap from '../../asset/uniswap.png'
import dextool from '../../asset/dextools.png'
import insta from '../../asset/insta.png'


const Hero = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div id='#home' >
            <div className="container hero__inner-cont">


                <div className="  hero__inner-cont__left" data-aos="zoom-out-right" data-aos-duration="1000">
                    <p className='heading'> Join the ShibaDopt Rescues</p>
                    <h1 className='hero-heading'> <span>SHIBADOPT</span>
                    {/* A Cryptocurrency Project for Dog Rescue
                        Organisations Project for Dog Rescue Organisation */}
                    </h1>
                    <h1 className='hero-heading'>  </h1>
                    <p className="">
                        SHIBADOPT is a cryptocurrency project with the primary goal of supporting dog rescue organisations
                        globally. Using blockchain technology.
                    </p>

                    <div className="buttondiv">

                        <button className="btn hero-btn">
                            <a href="https://t.me/shibadoptverify" target="_blank" rel="noopener noreferrer">Join Community </a>
                        </button>
                        <button className="btn hero-btn">
                            <a href="https://shibalaunch.com/project/0x779d8ad3d34919a2251801f161b98f44d7f6649a" target="_blank" rel="noopener noreferrer">Buy Shibadopt</a>
                        </button>
                    </div>
                    
                    <div className="logo-link flex">
                        <a href="https://x.com/shibadopt" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a>
                        <a href="https://t.me/shibadoptverify" target="_blank" rel="noopener noreferrer"><img src={telegram} alt="telegram" /></a>
                        <a className='insta' href="https://instagram.com/shibadopt" target="_blank" rel="noopener noreferrer"><img src={insta} alt="Instagram" /></a>
                        <a href="#" target="_blank" rel="noopener noreferrer"><img src={etherscan} alt="etherscan" /></a>
                        <a href="#" target="_blank" rel="noopener noreferrer"><img src={uniswap} alt="uniswap" /></a>
                        <a href="" target="_blank" rel="noopener noreferrer"><img src={dextool} alt="dextool" /></a>
                    </div>
                </div>

                <div className="hero-img" data-aos="zoom-out-left" data-aos-duration="1000">

                    <img src={lig} alt="hero picture" className="image" />
                </div>
            </div>






        </div>
    )
}

export default Hero